@import './fonts.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}


* {
  box-sizing: border-box;
}

.pictureContainer {
  height: 100%;
  overflow-y: scroll;
}


.pictureContainer h1 {
  font-size: 56;
  margin-bottom: 20px;
  color: white;
}

.pictureContainer img {
  margin-bottom: auto;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  background-color: #f7f7f6;
}

.topContainer .backArrow {
  padding-left: 5px;
}

.rightContainer {
  display: flex;
  align-items: center;
}

.topContainer button {
 border: none;
background-color: #f7f7f6;
}

.icons {
  padding: 10px 25px 0px 25px;
  position: relative;
  display: flex;
  align-items: flex-start;
  background-color: #f7f7f6;
  display: flex;
  justify-content: space-between;
  height: 100px;
  border-top: 2px solid grey;
  border-bottom: 2px solid lightgrey;
}

.divider {
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin-left: -75px; 
  width: 150px; 
  height: 6px;
  border-radius: 5px;
  background-color: black;
}


.buttonContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  height: 100px;
  background-color: white;
}

.buttonContainer .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.buttonContainer button {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 10px; 
  background-color: #949494;
  border-radius: 50%;
  border: none;
}


.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  position: relative;
  max-width: 440px;
  margin: 0 auto;
  height: 100vh;
  border-radius: 10px;
}


.otherContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0f1731;
  position: relative;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  height: 100vh;
  padding: 0 40px;
  border-radius: 10px;
}

.otherContainer h1 {
  color: white;
  text-align: center;
  font-size: 56;
  font-family: 'Citi-Sans-Text-Regular';
}

.otherContainer img {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  margin: 0 auto;
}


img {
  max-width: 50px;
}

.images {
  max-width: 100%;
}




  